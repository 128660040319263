<template>
  <div v-if="isHeaderSticked" :style="{ height: headerHeight + 'px' }"></div>
  <div ref="header" class="z-40 header">
    <header class="container__fullwidth flex items-center justify-between">
      <NuxtLink to="/">
        <AppLogo class="grow" />
      </NuxtLink>
      <AppMainNavigation />
    </header>
  </div>
</template>

<script setup lang="ts">
const header = ref<Element | null>(null)
const headerHeight = ref(0)
const isHeaderSticked = ref(false)

onMounted(() => {
  headerHeight.value = header.value?.clientHeight!
  window.addEventListener('scroll', handleResize)
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleResize)
})

const handleResize = () => {
  if (window.scrollY >= header.value?.clientHeight!) {
    isHeaderSticked.value = true
    header.value!.classList.add('header--stick')
  } else {
    isHeaderSticked.value = false
    header.value!.classList.remove('header--stick')
  }
}

onUnmounted(() => {
  window.removeEventListener('scroll', handleResize)
})
</script>

<style lang="scss">
.header {
  &:not(.header--stick) {
    @apply pt-6 lg:pt-10 pb-6 lg:pb-10;
  }

  &.header--stick {
    @apply block bg-secondary-light py-4 fixed top-0 left-0 w-full;
  }
}
</style>
