<template>
  <nav v-if="mainNavigation.length" class="flex items-center justify-end md:space-x-20">
    <ul v-if="topSites.length" class="hidden lg:flex lg:space-x-20 uppercase font-serif font-bold text-xl text-grey">
      <li v-for="(item, index) in topSites" :key="index">
        <NuxtLink :to="item.path" :target="item.type === 'EXTERNAL' ? '_blank' : ''">{{ item.title }}</NuxtLink>
      </li>
    </ul>
    <UtilityButton is-button class="bg-secondary !p-4" @click="openNavigation = true" @mouseenter="animation($event, 'heartBeat')">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-primary">
        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 9h16.5m-16.5 6.75h16.5" />
      </svg>
      <span class="sr-only">Open menu button</span>
    </UtilityButton>
    <ClientOnly>
      <Teleport to="body">
        <LazyAppMenuScreen v-if="openNavigation" />
      </Teleport>
    </ClientOnly>
  </nav>
</template>
<script setup lang="ts">
import useAnimation from '~/composables/useAnimation';

const mainNavigation = useMainNavigation()
const footerNavigation = useFooterNavigation()
const openNavigation = useOpenNavigation()
const animation = useAnimation()
const topSites = reactive([])

if (mainNavigation.value?.length || footerNavigation.value?.length) {
  for (const item of mainNavigation.value) {
    if (topSites.length >= 2) break
    if (item.isImportant) topSites.push(item)
  }
  for (const item of footerNavigation.value) {
    if (topSites.length >= 2) break
    if (item.isImportant) topSites.push(item)
  }
}
</script>
